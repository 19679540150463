import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class PermisionService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getModulesPermission(args) {
      return axiosService.axiosIns.get(this.jwtConfig.getModulesPermission, { params: args })
    }
}

function usePermisionService() {
  const permissionService = new PermisionService()

  return {
    permissionService,
  }
}

const { permissionService } = usePermisionService()
export default permissionService
