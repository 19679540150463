import Vue from 'vue'
import VueRouter from 'vue-router'
import permissionService from '@core/services/permissions/permissionService'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/cli/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/inicio',
      name: 'inicio',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
        requiresAuth: false,
      },
    },
    {
      path: '/two-factor-auth',
      name: 'two-factor-auth',
      component: () => import('@/views/TwoFactorAuth.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: () => import('@/views/Auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/changeMyPassword',
      name: 'changeMyPassword',
      component: () => import('@/views/Auth/RecoverPassword.vue'),
      meta: {
        requiresAuth: true,
        layout: 'full',
      },
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: () => import('@/views/Auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    // Settings
    {
      path: '/config/permisos',
      name: 'config-permisos',
      component: () => import('@/views/settings/ModulePermission.vue'),
    },
    {
      path: '/config/catalogs',
      name: 'config-catalogs',
      component: () => import('@/views/settings/catalogs/Catalogs.vue'),
    },
    // Invoice
    {
      path: '/reportes/estiloscatalogo',
      name: 'estilos-catalogo',
      component: () => import('@/views/reports/EstilosCatalogo.vue'),
    },
    {
      path: '/apps/invoice/preview/:id',
      name: 'apps-invoice-preview',
      component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    },
    {
      path: '/apps/invoice/add/',
      name: 'apps-invoice-add',
      component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    },
    {
      path: '/apps/invoice/edit/:id',
      name: 'apps-invoice-edit',
      component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/system/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/faq',
    //   name: 'faq',
    //   component: () => import('@/views/faq/Faq.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     pageTitle: 'FAQ',
    //     breadcrumb: [
    //       {
    //         text: 'Pages',
    //       },
    //       {
    //         text: 'FAQ',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/',
      redirect: 'login',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen1
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  try {
    if (to.name === 'login') next()
    const user = store.getters['user-auth/getUser']
    const permission = await permissionService.getModulesPermission({ agentId: user.name })
    store.commit('user-auth/auth_user_permission', permission)
    console.log(permission.data)
    next()
  } catch (error) {
    console.log(error)
    next()
  }
})

export default router
