export default {
  namespaced: true,
  state: {
    user: {},
    token: '',
    permission: [],
  },
  getters: {
    getUser: state => state.user,
    isLoggedIn: state => !!state.token,
    requireResetPassword: state => !!state.user.change_password,
    getToken: state => state.token,
    getPermission: state => state.permission,
  },
  mutations: {
    auth_success(state, objauth) {
      state.token = objauth.token
      state.user = objauth.user
    },
    auth_user(state, user) {
      state.user = user
    },
    auth_user_permission(state, permission) {
      state.permission = permission
    },
    auth_password_reseted(state) {
      state.user.change_password = false
    },
    auth_denied(state) {
      state.user = {}
      state.token = ''
    },
  },
}
