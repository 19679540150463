export default {

  baseUrl: 'https://pv.grupokarsten.com.mx', // Sebastian Maquina

  // Endpoints
  loginEndpoint: '/api/Auth/login',
  verifyAuthyCodeEndpoint: '/api/v1/auth/verify',
  resetPassword: '/api/v1/auth/change-password',
  recoveryPassword: '/api/v1/auth/recover-password',
  forgotPassword: '/api/v1/auth/forgot-password',

  getModulesPermission: '/api/auth/getModulePermissions',

  getCatalogAll: '/api/catalog/fetchCatalog',
  getCatalog: '/api/catalog/fetchCatalogElement',
  updateCatalogElement: '/api/catalog/updateCatalogElement',
  deleteCatalogElement: '/api/catalog/deleteCatalogElement',
  createCatalogElement: '/api/catalog/createCatalogElement',

  getStylesByCatalog: '/api/Report/getStylesByCatalog',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
